export default [
    {
        path: '/',
        name: 'Layout',
        component: () => import('@/container/pages/Layout.vue'),
        children: [
            {
                path: '/',
                name: 'Dashboard',
                component: () => import('@/container/pages/Dashboard.vue'),
                meta: { requiresAuth: true, roles: ['admin', 'vendor', 'staff']}
            },
            {
                path: '/management/:type',
                name: 'Vendor',
                component: () => import('@/container/pages/Vendor.vue'),
                meta: { requiresAuth: true, roles: ['admin']}
            },
            {
                path: '/transactions',
                name: 'TransactionsHistory',
                component: () => import('@/container/pages/TransactionsHistory.vue'),
                meta: { requiresAuth: true, roles: ['admin', 'staff']}
            },
            {
                path: '/order',
                name: 'PlaceOrder',
                component: () => import('@/container/pages/PlaceOrder.vue'),
                meta: { requiresAuth: true, roles: ['admin', 'vendor']}
            },
            {
                path: '/bulkOrder',
                name: 'PlaceBulkOrder',
                component: () => import('@/container/pages/PlaceBulkOrder.vue'),
                meta: { requiresAuth: true, roles: ['admin', 'vendor']}
            },
            {
                path: '/bulkTransactions',
                name: 'BulkTransactions',
                component: () => import('@/container/pages/BulkTransactions.vue'),
                meta: { requiresAuth: true, roles: ['admin', 'staff']}
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/container/auth/Login.vue'),
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/container/pages/404Page.vue')
    },
    {
        path: '/forgotPassword',
        name: 'ForgotPassword',
        component: () => import('@/container/auth/ForgotPassword.vue')
    },
    {
        path: '/changePassword',
        name: 'ChangePassword',
        component: () => import('@/container/auth/ChangePassword.vue')
    },
    {
        path: '/profile',
        name: 'ProfilePage',
        component: () => import('@/container/auth/Profile.vue')
    }
]