import { createRouter, createWebHistory } from 'vue-router';
import useStorage from '@/mixins/GlobalMixins';
import routes from './router';
const { getObjectFromBase64 } = useStorage()

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })

router.beforeEach((to, from, next) => {
    const isAuthenticated = getObjectFromBase64('userData'); 
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);

    if (authRequired && !isAuthenticated?.userName) {
        next('/login');
    } 
    const allowedRoles = to.meta.roles;
    if (authRequired && allowedRoles && !allowedRoles.includes(isAuthenticated?.userType)) {
        return next('/404');
    }
    else{
        next()
    }
});
  
export default router