// import { ref } from 'vue';

export default function useStorage() {
    const setObjectToBase64 = (key, obj) => {
        try {
            const jsonString = JSON.stringify(obj);
            const base64String = btoa(jsonString);
            localStorage.setItem(key, base64String);
        } catch (error) {
            console.error('Error encoding to Base64:', error);
        }
    };

    const getObjectFromBase64 = (key) => {
        try {
            const base64String = localStorage.getItem(key);
            if (!base64String) return null;

            const jsonString = atob(base64String);
            return JSON.parse(jsonString);
        } catch (error) {
            console.error('Error decoding from Base64:', error);
            return null;
        }
    };
    
    const toCamelCase = (str) => {
        return str.toLowerCase().replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
            index === 0 ? match.toUpperCase() : match.toLowerCase()
        ).replace(/\s+/g, '');
    }

    const buildMenuTree = (menuItems) => {
        const menuMap = {};
        const result = [];
    
        menuItems.forEach(menu => {
            menuMap[menu.menu_id] = { ...menu, childMenus: [] };
        });
    
        menuItems.forEach(menu => {
            if (menu.parent_menu_id) {
                menuMap[menu.parent_menu_id].childMenus.push(menuMap[menu.menu_id]);
            } else {
                result.push(menuMap[menu.menu_id]);
            }
        });
    
        return result;
    }
    
    const ddmmyyyyConvertion = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        return formattedDate; // Output: "3/11/2024"

    }

    return { setObjectToBase64, getObjectFromBase64, toCamelCase, buildMenuTree, ddmmyyyyConvertion };
}